var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"col-span-5 md:col-span-1 p-2 xl:p-5 w-full flex flex-col justify-between bg-white transition duration-300 shadow-sidebar rounded-10"},[_c('div',{},[_vm._m(0),_c('div',{staticClass:"rounded-10 flex flex-col p-3 border border-primary shadow-sidebar"},[_c('div',{staticClass:"flex space-x-2 items-center justify-start mb-2"},[_c('div',{staticClass:"overflow-hidden rounded-full h-11 w-11 flex flex-none"},[_c('img',{staticClass:"object-cover w-full h-full",attrs:{"src":_vm.user.foto,"alt":""}})]),_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"text-black font-semibold text-sm"},[_vm._v(" "+_vm._s(_vm.user.nama)+" ")]),_c('span',{staticClass:"text-black font-medium text-xs"},[_vm._v(" "+_vm._s(_vm.user.telepon)+" ")])])]),_c('span',{staticClass:"font-medium text-sm text-primary"},[_vm._v("Balance")]),_c('div',{staticClass:"flex justify-between items-center"},[_c('span',{staticClass:"font-semibold text-2xl leading-5 text-primary"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.saldo.SaldoAktif))+" ")]),_c('img',{class:[
            'transform w-3 h-2 cursor-pointer',
            _vm.showDetail ? 'rotate-180' : '',
          ],attrs:{"src":_vm.icTriangle,"alt":""},on:{"click":_vm.detailSaldo}})]),(_vm.showDetail)?_c('div',{staticClass:"flex flex-col mt-2"},[_c('span',{staticClass:"text-x font-medium text-black px-0 md:px-3"},[_vm._v("Active Balance")]),_c('span',{staticClass:"text-sm font-bold text-black leading-0 px-0 md:px-5"},[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.saldo.SaldoAktif)))]),_c('span',{staticClass:"text-x font-medium text-black px-0 md:px-3"},[_vm._v("Hold Balance")]),_c('span',{staticClass:"text-sm font-bold text-black leading-0 px-0 md:px-5"},[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.saldo.SaldoPasif)))])]):_vm._e(),_c('span',{staticClass:"text-primary text-sm font-medium mt-3"},[_vm._v("BalancePoint")]),_c('span',{staticClass:"text-primary text-xl font-semibold leading-0"},[_vm._v(_vm._s(_vm.saldoPoin))])]),_c('div',{staticClass:"flex flex-row w-full justify-between"},[_c('div',{staticClass:"mt-8 flex flex-col space-y-3"},[_c('div',{staticClass:"flex items-center space-x-2 cursor-pointer"},[_c('img',{attrs:{"src":_vm.isActive === 'transactions' ? _vm.icTransAc : _vm.icTrans,"alt":"Recent Transactions"}}),_c('router-link',{class:[
              'font-semibold text-base hover:text-secondary',
              _vm.isActive === 'transactions' ? 'text-primary' : 'text-black',
            ],attrs:{"to":{name: 'Transactions'}}},[_vm._v("Transactions")])],1),_c('div',{staticClass:"flex items-center space-x-2 cursor-pointer"},[_c('img',{attrs:{"src":_vm.isActive === 'transfers' ? _vm.icTfAc : _vm.icTf,"alt":"Transfers"}}),_c('router-link',{class:[
              'font-semibold text-base hover:text-secondary',
              _vm.isActive === 'transfers' ? 'text-primary' : 'text-black',
            ],attrs:{"to":{ name: 'ToTripwePay' }}},[_vm._v("Transfers")])],1),_c('div',{staticClass:"flex items-center space-x-2 cursor-pointer"},[_c('img',{attrs:{"src":_vm.isActive === 'settings' ? _vm.icSetAc : _vm.icSet,"alt":"Settings"}}),_c('router-link',{class:[
              'font-semibold text-base hover:text-secondary',
              _vm.isActive === 'settings' ? 'text-primary' : 'text-black',
            ],attrs:{"to":_vm.redirectPinForm}},[_vm._v("PIN Balance")])],1)]),_c('div',{staticClass:"flex mt-8 md:hidden"},[_c('qrcode',{staticClass:"rounded-10 shadow-sidebar",attrs:{"value":_vm.user.va,"options":{ width: 120 },"tag":"img"}})],1)])]),_c('div',{staticClass:"flex justify-start mt-5 pb-2 overflow-hidden md:block"},[_c('qrcode',{staticClass:"rounded-10 shadow-sidebar",attrs:{"value":_vm.user.va,"options":{ width: 120 },"tag":"img"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center gap-4 justify-start mb-4"},[_c('div',{staticClass:"my-2 text-center"},[_c('img',{attrs:{"src":require("@/assets/logo-balance.png"),"alt":""}})]),_c('span',{staticClass:"text-1a font-semibold text-xl"},[_vm._v("Balance")])])
}]

export { render, staticRenderFns }