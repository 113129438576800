<template>
  <aside class="col-span-5 md:col-span-1 p-2 xl:p-5 w-full flex flex-col justify-between bg-white transition duration-300 shadow-sidebar rounded-10">
    <div class="">
      <div class="flex items-center gap-4 justify-start mb-4">
        <div class="my-2 text-center">
          <img src="@/assets/logo-balance.png" alt="" />
        </div>
        <span class="text-1a font-semibold text-xl">Balance</span>
      </div>
      <div class="rounded-10 flex flex-col p-3 border border-primary shadow-sidebar">
        <div class="flex space-x-2 items-center justify-start mb-2">
          <div class="overflow-hidden rounded-full h-11 w-11 flex flex-none">
            <img :src="user.foto" class="object-cover w-full h-full" alt="" />
          </div>
          <div class="flex flex-col">
            <span class="text-black font-semibold text-sm">
              {{ user.nama }}
            </span>
            <span class="text-black font-medium text-xs">
              {{ user.telepon }}
            </span>
          </div>
        </div>
        <span class="font-medium text-sm text-primary">Balance</span>
        <div class="flex justify-between items-center">
          <span class="font-semibold text-2xl leading-5 text-primary">
            {{ saldo.SaldoAktif | toCurrency }}
          </span>
          <img
            @click="detailSaldo"
            :class="[
              'transform w-3 h-2 cursor-pointer',
              showDetail ? 'rotate-180' : '',
            ]"
            :src="icTriangle"
            alt=""
          />
        </div>
        <div v-if="showDetail" class="flex flex-col mt-2">
          <span class="text-x font-medium text-black px-0 md:px-3"
            >Active Balance</span
          >
          <span class="text-sm font-bold text-black leading-0 px-0 md:px-5">{{
            saldo.SaldoAktif | toCurrency
          }}</span>
          <span class="text-x font-medium text-black px-0 md:px-3"
            >Hold Balance</span
          >
          <span class="text-sm font-bold text-black leading-0 px-0 md:px-5">{{
            saldo.SaldoPasif | toCurrency
          }}</span>
        </div>
        <span class="text-primary text-sm font-medium mt-3">BalancePoint</span>
        <span class="text-primary text-xl font-semibold leading-0">{{
          saldoPoin
        }}</span>
      </div>
      <div class="flex flex-row w-full justify-between">
        <div class="mt-8 flex flex-col space-y-3">
          <div class="flex items-center space-x-2 cursor-pointer">
            <img
              :src="isActive === 'transactions' ? icTransAc : icTrans"
              alt="Recent Transactions"
            />
            <router-link
              :to="{name: 'Transactions'}"
              :class="[
                'font-semibold text-base hover:text-secondary',
                isActive === 'transactions' ? 'text-primary' : 'text-black',
              ]"
              >Transactions</router-link
            >
          </div>
          <div class="flex items-center space-x-2 cursor-pointer">
            <img
              :src="isActive === 'transfers' ? icTfAc : icTf"
              alt="Transfers"
            />
            <router-link
              :to="{ name: 'ToTripwePay' }"
              :class="[
                'font-semibold text-base hover:text-secondary',
                isActive === 'transfers' ? 'text-primary' : 'text-black',
              ]"
              >Transfers</router-link
            >
          </div>
          <div class="flex items-center space-x-2 cursor-pointer">
            <img
              :src="isActive === 'settings' ? icSetAc : icSet"
              alt="Settings"
            />
            <router-link
              :to="redirectPinForm"
              :class="[
                'font-semibold text-base hover:text-secondary',
                isActive === 'settings' ? 'text-primary' : 'text-black',
              ]"
              >PIN Balance</router-link
            >
          </div>
        </div>
        <div class="flex mt-8 md:hidden">
          <qrcode
            :value="user.va"
            :options="{ width: 120 }"
            tag="img"
            class="rounded-10 shadow-sidebar"
          ></qrcode>
        </div>
      </div>
    </div>
    <div class="flex justify-start mt-5 pb-2 overflow-hidden md:block">
      <qrcode
        :value="user.va"
        :options="{ width: 120 }"
        tag="img"
        class="rounded-10 shadow-sidebar"
      ></qrcode>
    </div>
  </aside>
</template>

<script>
import { NumberFormat } from "../utils";
import Vue from "vue";

export default {
  name: "SidebarUser",
  data: () => ({
    showDetail: false,
    icTopup: require("@/assets/icons/ic-topup.svg"),
    icTopupAc: require("@/assets/icons/ic-topup-active.svg"),
    icTf: require("@/assets/icons/ic-tf.svg"),
    icTfAc: require("@/assets/icons/ic-tf-active.svg"),
    icTrans: require("@/assets/icons/ic-trans.svg"),
    icTransAc: require("@/assets/icons/ic-trans-active.svg"),
    icSet: require("@/assets/icons/ic-setting.svg"),
    icSetAc: require("@/assets/icons/ic-setting-active.svg"),
    icTriangle: require("@/assets/icons/ic-triangle.svg"),
  }),
  mounted() {},
  methods: {
    numberFormat: (num) => NumberFormat(num),
    detailSaldo() {
      this.showDetail = !this.showDetail;
    },
  },
  computed: {
    redirectPinForm(){
      if(this.$store.state.user.status_pin == 1){
        return {name: 'Settings'};
      }else{
        return {name: 'CreatePin'};
      }
    },
    saldoPoin() {
      var val = Vue.filter("toCurrency")(this.saldo.Poin);
      val = val.replace("RM", "");
      val = val.replace("Rp", "");
      val = val.replace(".", "");
      val = val.replace(" ", "");
      return val;
    },
    user() {
      return this.$store.state.user;
    },
    saldo() {
      return this.$store.state.saldo;
    },
    isActive() {
      return this.$route.matched[1].path.split("/").pop();
    },
  },
};
</script>
